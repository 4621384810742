import LineAngleRightIcon from 'components/Luxkit/Icons/line/LineAngleRightIcon'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import withMountGuard from 'hocs/withMountGuard'
import { useAppSelector } from 'hooks/reduxHooks'
import LuxLoyaltyProgramNameLogo from 'luxLoyalty/components/LuxLoyaltyProgramNameLogo'
import { LUX_LOYALTY } from 'luxLoyalty/constants/base'
import { getIsLuxLoyaltyEnabled, getHasLuxLoyaltyAccount } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import Caption from 'components/Luxkit/Typography/Caption'
import Divider from 'components/Luxkit/Divider'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import getLuxLoyaltyTierDisplayName from 'luxLoyalty/selectors/getLuxLoyaltyTierDisplayName'
import FormatDecimal from 'components/utils/Formatters/FormatDecimal'
import config from 'constants/config'
import { getPlural } from 'lib/string/pluralize'
import { clamp } from 'lib/maths/mathUtils'
import LuxLoyaltyAccountTileCircularProgressBar from 'luxLoyalty/components/LuxLoyaltyAccountTileCircularProgressBar'
import cn from 'clsx'
import { mediaQueryUp } from 'components/utils/breakpoint'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import useLuxLoyaltyUserBenefit from 'luxLoyalty/hooks/useLuxLoyaltyUserBenefit'
import Clickable from 'components/Common/Clickable'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'

const Container = styled(Clickable)`
  color: ${props => props.theme.palette.product.luxPlus.contrast};
  border-radius: ${props => props.theme.borderRadius.M};
  /** To see the effect of the border-radius */
  overflow: hidden;

  &:focus {
    outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid  ${props => props.theme.palette.neutral.default.five});
    outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
  }
`

const HeaderRow = styled(Group)`
  padding-block: ${rem(4)};
  padding-inline: ${rem(16)} ${rem(12)};
  height: ${rem(32)};

  &.tier-bronze {
    background-color: ${props => props.theme.palette.product.luxLoyalty.bronze.normalBackground};
    color: ${props => props.theme.palette.product.luxLoyalty.bronze.normalContrast};
  }

  &.tier-silver {
    background-color: ${props => props.theme.palette.product.luxLoyalty.silver.normalBackground};
    color: ${props => props.theme.palette.product.luxLoyalty.silver.normalContrast};
  }

  &.tier-gold {
    background-color: ${props => props.theme.palette.product.luxLoyalty.gold.normalBackground};
    color: ${props => props.theme.palette.product.luxLoyalty.gold.normalContrast};
  }

  &.tier-platinum {
    background-color: ${props => props.theme.palette.product.luxLoyalty.platinum.normalBackground};
    color: ${props => props.theme.palette.product.luxLoyalty.platinum.normalContrast};
  }
`

const LoadingHeaderRow = styled.div`
  padding-block: ${rem(10)};
  padding-inline: ${rem(16)} ${rem(8)};
  background-color: ${props => props.theme.palette.product.luxLoyalty.bronze.darkBackground};
`

const SubContainer = styled.div`
  background: linear-gradient(
    275deg,
    ${props => props.theme.palette.neutral.default.one},
    ${props => props.theme.palette.neutral.control.black100}
  );

  &.tier-bronze {
    padding: ${rem(20)} ${rem(16)};
  }

  &:not(.tier-bronze) {
    padding: ${rem(20)} ${rem(36)};
  }
`

const AccountSnapshotContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  ${mediaQueryUp.tablet} {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
  }
`

const AccountSnapshotContainerWithRoomUpgrades = styled(AccountSnapshotContainer)`
  display: flex;
  justify-content: space-evenly;

  ${mediaQueryUp.tablet} {
    display: flex;
    justify-content: center;
    gap: ${rem(32)};
  }
`

const StatusCreditCaption = styled(Caption)`
  max-width: min-content;
`

const LuxLoyaltyDivider = styled(Divider)`
  background-color: ${props => props.theme.palette.neutral.default.one} !important;
`

const FadedLoadingBox = styled(TextLoadingBox)`
  opacity: 0.3;
`

const StyledCaption = styled(Caption)`
  display: flex;
  align-items: flex-end;
`

interface Props {
  onLinkClick: () => void
}

function AccountMenuLuxLoyaltyDetailsTile({ onLinkClick }: Props) {
  const luxLoyaltyAccount = useAppSelector(state => state.luxLoyalty.account)
  const hasLuxLoyaltyAccount = useAppSelector(getHasLuxLoyaltyAccount)
  const luxLoyaltyRoomUpgradeBenefitDetails = useLuxLoyaltyUserBenefit('hotel_upgrade')
  const hasRoomUpgradesAvailable = !!luxLoyaltyRoomUpgradeBenefitDetails?.remaining

  const accountTier = luxLoyaltyAccount.data?.tier
  const points = luxLoyaltyAccount?.data?.points ?? 0
  const statusCredits = luxLoyaltyAccount?.data?.statusCredits ?? 0
  const totalCreditsForTierRetention = luxLoyaltyAccount?.data?.totalCreditsForTierRetention ?? 0
  const totalCreditsForTierUpgrade = luxLoyaltyAccount?.data?.totalCreditsForTierUpgrade ?? 0
  const nextTierCreditsRequired = accountTier === 'platinum' ? totalCreditsForTierRetention : totalCreditsForTierUpgrade
  const progress = Math.floor((statusCredits / (accountTier === 'platinum' ? totalCreditsForTierRetention : nextTierCreditsRequired)) * 100)
  const clampedProgress = clamp(0, progress, 100)

  if (luxLoyaltyAccount.fetching) {
    return <Container>
      <LoadingHeaderRow>
        <Caption variant="small" weight="bold" format="uppercase">
          <LuxLoyaltyProgramNameLogo height={12} />&emsp;<FadedLoadingBox width="8ch" />
        </Caption>
      </LoadingHeaderRow>
      <SubContainer>
        <AccountSnapshotContainer>
          <FadedLoadingBox width={120} lines={1} typography="heading1" />
          <LuxLoyaltyDivider kind="secondary" direction="vertical" />
          <FadedLoadingBox width="100%" lines={1} typography="heading1" />
        </AccountSnapshotContainer>
      </SubContainer>
    </Container>
  }

  if (!hasLuxLoyaltyAccount || luxLoyaltyAccount.error) return null

  return <Container to={`/${LUX_LOYALTY.LANDING_PAGE}`} onClick={onLinkClick}>
    <HeaderRow
      direction="horizontal"
      horizontalAlign="space-between"
      verticalAlign="center"
      className={cn(`tier-${accountTier}`)}
    >
      <StyledCaption variant="small" weight="bold" format="uppercase">
        <LuxLoyaltyProgramNameLogo height={12} />&emsp;{getLuxLoyaltyTierDisplayName(accountTier!)} {luxLoyaltyAccount.data?.accountNumber && `• ${luxLoyaltyAccount.data?.accountNumber}`}
      </StyledCaption>
      <LineAngleRightIcon size="S" />
    </HeaderRow>
    <SubContainer className={cn(`tier-${accountTier}`)}>
      {!hasRoomUpgradesAvailable && <AccountSnapshotContainer>
        <Group direction="horizontal" verticalAlign="center" gap={8}>
          <LuxLoyaltyAccountTileCircularProgressBar
            progress={clampedProgress}
            tier={accountTier!}
          >
            <Heading variant="heading6" colour="neutral-eight">
              <FormatDecimal value={statusCredits} />
            </Heading>
          </LuxLoyaltyAccountTileCircularProgressBar>
          <StatusCreditCaption variant="medium" colour="neutral-four" format="propercase">
            {getPlural(config.luxLoyalty.statusCreditsUnit)}
          </StatusCreditCaption>
        </Group>

        <div>
          <LuxLoyaltyDivider kind="secondary" direction="vertical" />
        </div>

        <Group direction="vertical" gap={8} verticalAlign="center" horizontalAlign="center">
          <VerticalSpacer gap={2}>
            <Caption variant="medium" colour="neutral-four" format="propercase">
              {getPlural(config.luxLoyalty.pointsUnit)}
            </Caption>
            <Heading variant="heading6" colour="neutral-eight">
              <FormatDecimal value={points} />
            </Heading>
          </VerticalSpacer>
        </Group>
      </AccountSnapshotContainer>}

      {hasRoomUpgradesAvailable && <AccountSnapshotContainerWithRoomUpgrades>
        <Group direction="horizontal" verticalAlign="center" gap={8}>
          <LuxLoyaltyAccountTileCircularProgressBar
            progress={clampedProgress}
            tier={accountTier!}
          >
            <Heading variant="heading6" colour="neutral-eight">
              <FormatDecimal value={statusCredits} />
            </Heading>
          </LuxLoyaltyAccountTileCircularProgressBar>
          <StatusCreditCaption variant="medium" colour="neutral-four" format="propercase">
            {getPlural(config.luxLoyalty.statusCreditsUnit)}
          </StatusCreditCaption>
        </Group>

        <div>
          <LuxLoyaltyDivider kind="secondary" direction="vertical" />
        </div>

        <Group direction="vertical" gap={8} verticalAlign="center" horizontalAlign="start">
          <Group direction="vertical" gap={2} verticalAlign="center">
            <Caption variant="medium" colour="neutral-four" format="propercase">
              {getPlural(config.luxLoyalty.pointsUnit)}
            </Caption>
            <Heading variant="heading6" colour="neutral-eight">
              <FormatDecimal value={points} />
            </Heading>
          </Group>
          <Group direction="vertical" gap={2}>
            <Caption variant="medium" colour="neutral-four">
              Room upgrades
            </Caption>
            <Heading variant="heading6" colour="neutral-eight">
              {typeof luxLoyaltyRoomUpgradeBenefitDetails?.remaining === 'undefined' && <>Unlimited</>}
              {typeof luxLoyaltyRoomUpgradeBenefitDetails?.remaining === 'number' && <>{luxLoyaltyRoomUpgradeBenefitDetails.remaining}x Available</>}
            </Heading>
          </Group>
        </Group>

      </AccountSnapshotContainerWithRoomUpgrades>}

    </SubContainer>
  </Container>
}

export default withMountGuard(() => {
  return useAppSelector(getIsLuxLoyaltyEnabled)
})(AccountMenuLuxLoyaltyDetailsTile)
